<template>
  <div class="chat-page" :class="isMobile ? '' : 'ph2'">
    <div v-if="!isMobile" class="container-header f aic mb2">
      <div class="qcont h2 pr1 border-right">Trò chuyện</div>
      <div class="h5 pr05 pl1 brown">Quản lý trò chuyện</div>
    </div>

    <div class="f gap1 bg-white-1 border-radius px1 h100">
      <div
        v-if="(isMobile && !conversationActive) || !isMobile"
        class="conversation f fdc gap1"
        :style="`width: ${isMobile ? '100%' : '30%'}`"
      >
        <div
          v-if="role === 'ADMIN' || role === 'CONSULTANT'"
          class="conversation__container w100"
        >
          <h4>Tin nhắn khách hàng</h4>
          <div
            v-for="(item, index) in listConversationsCustomer"
            :key="index"
            class="conversation__item f gap1 px1 border-radius"
            :class="
              conversationActive &&
              conversationActive.id === item.id &&
              'bg-gray-2'
            "
            @click="getMessageOfConversation(item)"
          >
            <div class="avatar">
              <img
                v-if="item.receiver && item.receiver.customer"
                :src="item.receiver.customer.avatar || './avatar.jpeg'"
                alt="Hello"
              />
              <img v-else src="./avatar.jpeg" alt="" />
            </div>

            <div>
              <p v-if="item.receiver && item.receiver.phone" class="h6">
                {{ item.receiver.name }}
                <span class="gray italic">({{ item.receiver.phone }})</span>
              </p>
              <p v-if="item.receiver && item.receiver.customer" class="h6">
                {{ item.receiver.customer.full_name }}
                <span class="gray italic">(Khách hàng hệ thống)</span>
              </p>
              <p v-if="item.latest_message" class="h7 ellipsis">
                <span v-if="item.latest_message.sender == idUser" class="gray"
                  >Bạn:
                </span>
                {{ item.latest_message.text }}
              </p>
              <p v-else class="h7 gray">Chưa có tin nhắn</p>
            </div>
          </div>
        </div>
        <div class="conversation__container w100">
          <div class="f jcb">
            <h4>Tin nhắn nội bộ</h4>
            <p class="h4 brown cursor" @click="isShowPopupUser = true">+</p>
          </div>
          <div
            v-for="(item, index) in listConversationsInternal"
            :key="index"
            class="conversation__item f gap1 px1 border-radius"
            :class="
              conversationActive &&
              conversationActive.id === item.id &&
              'bg-gray-2'
            "
            @click="getMessageOfConversation(item)"
          >
            <div class="avatar">
              <img
                v-if="item.receiver && item.receiver.customer"
                :src="item.receiver.customer.avatar || './avatar.jpeg'"
                alt="Hello"
              />
              <img v-else src="./avatar.jpeg" alt="" />
            </div>

            <div>
              <p v-if="item.receiver && item.receiver.customer" class="h6">
                {{ item.receiver.customer.full_name }}
              </p>
              <p v-else class="h6">Người dùng không xác định</p>
              <p v-if="item.latest_message" class="h7 ellipsis">
                <span v-if="item.latest_message.sender == idUser" class="gray"
                  >Bạn:
                </span>
                {{ item.latest_message.text }}
              </p>
              <p v-else class="h7 gray">Chưa có tin nhắn</p>
            </div>
          </div>
        </div>
      </div>

      <div
        class="chat bg-gray-2 border-radius px1 relative"
        :style="`width: ${isMobile ? '100%' : '70%'}`"
        v-if="conversationActive"
      >
        <div
          class="chat__header bg-white-1 f gap1"
          @click="conversationActive = null"
        >
          <div class="avatar f aic gap05">
            <span v-if="isMobile" class="h3"> &lt; </span>
            <img
              v-if="
                conversationActive.receiver &&
                conversationActive.receiver.customer
              "
              :src="
                conversationActive.receiver.customer.avatar || './avatar.jpeg'
              "
              alt="Hello"
            />
            <img v-else src="./avatar.jpeg" alt="" />
          </div>
          <p
            v-if="
              conversationActive.receiver &&
              conversationActive.receiver.customer
            "
            class="h6"
          >
            {{ conversationActive.receiver.customer.full_name }}
          </p>
          <p
            v-else-if="
              conversationActive.receiver && conversationActive.receiver.phone
            "
            class="h6"
          >
            {{ conversationActive.receiver.name }}
            <span class="gray italic"
              >({{ conversationActive.receiver.phone }})</span
            >
          </p>
          <p v-else class="h6">Người dùng không xác định</p>
        </div>

        <p
          v-if="
            conversationActive.type === 'CUSTOMER' &&
            !checkUserJoinedConversation
          "
          class="chat__input bg-white-1 p6"
        >
          Bạn chưa tham gia cuộc trò chuyện này. Nhấn
          <span class="cursor h7 brown" @click="handleJoinConversation"
            >Tham gia</span
          >
          để tham gia cuộc trò chuyện!
        </p>

        <div v-else class="chat__input f gap05">
          <input
            v-model="inputMessage"
            placeholder="Nhập tin nhắn"
            style="height: 40px; width: 100%"
            @keyup.enter="handleSentMessage"
          />
          <button
            class="bg-brown ph1 border-radius white h7"
            style="height: 40px"
            @click="handleSentMessage"
          >
            Gửi
          </button>
        </div>

        <div id="message_wraper" class="message__container f fdc gap05">
          <div
            v-for="(itemMessage, indexMessage) in dataMessageOfConversation"
            :key="itemMessage.id"
            class="chat__message f w100 gap05"
            :class="[
              itemMessage.sender === idUser ? 'revert' : '',
              itemMessage.type === 'NOTI' && 'jcc aic gray-text',
            ]"
          >
            <div
              class="avatar--small f fdc jce"
              :style="
                indexMessage !== 0 &&
                dataMessageOfConversation[indexMessage - 1].sender ===
                  itemMessage.sender &&
                'opacity: 0;'
              "
            >
              <img
                v-if="
                  findUserMessage(itemMessage.sender) &&
                  findUserMessage(itemMessage.sender).customer
                "
                :src="
                  findUserMessage(itemMessage.sender).customer.avatar ||
                  './avatar.jpeg'
                "
                alt="Hello"
              />
              <img v-else src="./avatar.jpeg" alt="" />
            </div>
            <div
              class="f aic gap1"
              :class="itemMessage.sender === idUser ? 'revert' : ''"
            >
              <div
                class="message relative flex gap1 aic"
                :class="[
                  itemMessage.sender === idUser
                    ? 'message--me'
                    : 'message--partner',
                  itemMessage.type === 'NOTI' && 'message--noti',
                ]"
                @click="itemMessage.isShowInfo = !itemMessage.isShowInfo"
              >
                <p
                  v-if="
                    itemMessage.sender !== idUser &&
                    findUserMessage(itemMessage.sender) &&
                    findUserMessage(itemMessage.sender).customer
                  "
                  class="p7 gray mb025"
                  :style="
                    indexMessage !== 0 &&
                    dataMessageOfConversation[indexMessage - 1].sender ===
                      itemMessage.sender &&
                    'display: none;'
                  "
                >
                  {{ findUserMessage(itemMessage.sender).customer.full_name }}
                </p>
                {{ itemMessage.text }}
              </div>
              <span v-if="itemMessage.isShowInfo" class="h6 gray" style="bottom: -1em; left: 0">{{
                formatDate(itemMessage.created_at)
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Loading :is-loading="isLoading" type="page" />

    <NotiView
      v-if="notiView"
      @closePopup="notiView = false"
      :title="title"
      :logo="logo"
    />

    <Popup
      :is-show="isShowPopupUser"
      :width="'30vw'"
      @close="isShowPopupUser = false"
    >
      <div
        class="bg-white-1 px1 border-radius f fdc gap05 relative"
        style="padding-bottom: 80px; height: 80vh"
      >
        <div
          class="f jce w100 px1 border-top fixed bg-white-1 border-radius"
          style="bottom: 0; left: 0; right: 0; z-index: 10"
        >
          <button
            class="bg-brown ph15 pv075 border-radius white p5 cursor"
            @click="handleAddConvention"
          >
            Xác nhận
          </button>
        </div>

        <p class="h4">Chọn thành viên</p>
        <input
          v-model="searchUser"
          type="text"
          placeholder="Tìm kiếm"
          class="mv1"
        />
        <div class="f fdc gap1" style="overflow: auto">
          <label
            v-for="(item, index) in dataUsers.filter(
              (e) =>
                e.username.includes(searchUser) ||
                (e.customer && e.customer.full_name.includes(searchUser))
            )"
            :key="index"
            class="px1 border-radius f aic gap1"
            :class="
              userSelected && userSelected.id === item.id
                ? 'border-brown'
                : 'border'
            "
            @click="userSelected = item"
          >
            <input v-model="item.isSelected" type="radio" name="user" />
            <div class="avatar">
              <img
                v-if="item.customer && item.customer.avatar"
                :src="item.customer.avatar || './avatar.jpeg'"
                alt="Hello"
              />
              <img v-else src="./avatar.jpeg" alt="" />
            </div>
            <p v-if="item.customer" class="p5">
              {{ item.customer.full_name }}
            </p>
            <p v-else class="p5">
              {{ item.username }}
            </p>
          </label>
        </div>
      </div>
    </Popup>
  </div>
</template>
<!-- v-if="roleUser === 'ADMIN' || roleUser === 'CONSULTANT'" -->
<script>
import { HTTP } from "../../http-default";
import WebSocketService from "@/javascript/ws-message.js";
import Loading from "@/components/Loading/Loading.vue";
import NotiView from "@/components/NotiView/NotiView.vue";
import Popup from "@/components/Popup/index.vue";

export default {
  name: "ChatPage",

  components: {
    Loading,
    NotiView,
    Popup,
  },

  data() {
    return {
      isLoading: false,
      isLoadingConversation: false,
      notiView: false,
      logo: "noti-success",
      title: "",

      dataMessageSocket: [],

      pageMessage: 1,
      limitMessage: 100,
      dataMessageOfConversation: [],

      listConversationsCustomer: [],
      listConversationsInternal: [],

      conversationActive: null,

      inputMessage: "",

      // chọn người dùng để bắt đầu cuộc trò chuyện
      isShowPopupUser: false,
      searchUser: "",
      dataUsers: [],
      userSelected: null,
    };
  },

  computed: {
    role() {
      return localStorage.getItem("role");
    },

    idUser() {
      return Number(localStorage.getItem("idUser"));
    },

    checkUserJoinedConversation() {
      const flag = this.conversationActive?.users?.length
        ? this.conversationActive?.users.some(
            (item) => item?.user?.id === this.idUser
          )
        : false;

      return flag;
    },

    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
  },

  created() {
    this.getWebSocketMessage();
    this.getUsers();
  },

  beforeDestroy() {
    WebSocketService.socket.onclose();
  },

  methods: {
    getWebSocketMessage() {
      const token = localStorage.getItem("user");
      WebSocketService.connect(token);

      WebSocketService.socket.onmessage = (event) => {
        const data = JSON.parse(event.data);
        this.dataMessageSocket = data?.messages || [];

        if (this.conversationActive)
          this.getMessageOfConversation(this.conversationActive);
        this.getConversation();

        if (this.role === "ADMIN" || this.role === "CONSULTANT")
          this.getConversationCustomer();
      };
    },

    async getConversationCustomer() {
      this.isLoadingConversation = true;
      await HTTP.get("/chat/conversation-customer/")
        .then((res) => {
          const data = res?.data?.data || [];
          this.listConversationsCustomer = data;
          this.listConversationsCustomer.forEach((item) => {
            if (item?.users?.length) {
              const otherUser = item?.users.filter(
                (e) => e.user_guest || e.user.id != this.idUser
              );
              console.log(otherUser);

              item.receiver =
                otherUser?.[0]?.user_guest || otherUser?.[0]?.user;
            }
          });
          this.isLoadingConversation = false;
        })
        .catch(() => (this.isLoadingConversation = false));
    },

    async getConversation() {
      this.isLoadingConversation = true;
      await HTTP.get("/chat/conversation/")
        .then((res) => {
          const data = res?.data?.data || [];
          this.listConversationsInternal = data;

          this.listConversationsInternal.forEach((item) => {
            if (item?.users?.length) {
              const otherUser = item?.users.filter(
                (e) => e.user.id != this.idUser
              );
              item.receiver = otherUser?.[0]?.user;
            }
          });
          this.isLoadingConversation = false;
        })
        .catch(() => (this.isLoadingConversation = false));
    },

    async getMessageOfConversation(conversation) {
      this.conversationActive = conversation;
      if (this.conversationActive.id !== conversation.id)
        this.dataMessageOfConversation = [];
      this.isLoadingMessage = true;
      const payload = {
        page: this.pageMessage,
        limit: this.limitMessage,
        conversation: conversation.id,
      };
      await HTTP.post("/chat/message-of-conversation/", payload)
        .then((res) => {
          this.isLoadingMessage = false;
          const data = res.data?.data ? res.data?.data.results : [];
          this.dataMessageOfConversation = data.map((e) => ({...e, isShowInfo: false})).reverse();
        })
        .catch(() => (this.isLoadingMessage = false));
    },

    async handleSentMessage() {
      if (this.inputMessage.trim()) {
        const payload = {
          text: this.inputMessage.trim(),
          // receiver: 2,
          conversation: this.conversationActive.id,
          // receiver_guest: 5,
          sender: this.idUser,
        };
        this.inputMessage = "";
        await HTTP.post("/chat/create-message/", payload);
      }
    },

    async handleAddConvention() {
      this.isShowPopupUser = false;
      const payload = {
        name: "",
        type: "INTERNAL",
        add_list_user: [this.idUser, this.userSelected.id],
      };
      this.userSelected = false;
      await HTTP.post("/chat/conversation/", payload)
        .then(() => {
          this.getConversation();
        })
        .catch((err) => {
          this.notiView = true;
          this.logout = "noti-error";
          this.title = err?.response?.data?.data || err?.message;
        });
    },

    // tham gia cuộc trò chuyện với khách hàng
    async handleJoinConversation() {
      const payload = {
        conversation: this.conversationActive.id,
      };

      await HTTP.post("/chat/join-conversation/", payload)
        .then((res) => {
          const data = res?.data?.data || [];
          if (data?.users?.length) {
            const otherUser = data?.users.filter((e) => e.user_guest);
            data.receiver = otherUser?.[0]?.user_guest;
          }
          this.conversationActive = data;
          this.listConversationsCustomer = this.listConversationsCustomer.map(
            (item) => ({
              ...(item.id === data.id ? data : item),
            })
          );
          this.getMessageOfConversation(this.conversationActive);
        })
        .catch((err) => {
          this.notiView = true;
          this.logout = "noti-error";
          this.title = `Không thể tham gia cuộc trò chuyện này, vui lòng thử lại sau. Lỗi: ${
            err?.response?.data?.data || err?.message
          }`;
        });
    },

    // tìm kiếm tin nhắn của user nào
    findUserMessage(userSender) {
      let item = {};
      this.conversationActive?.users?.length
        ? this.conversationActive?.users.forEach((elm) => {
            if (elm?.user?.id === userSender) {
              item = elm.user;
            }
          })
        : false;

      return item;
    },

    async getUsers() {
      await HTTP.get(`/v1/api/user/`).then((res) => {
        this.dataUsers = res.data?.results || [];
        this.dataUsers = this.dataUsers.map((e) => ({
          ...e,
          isSelected: false,
        }));
      });
    },

    formatDate(date) {
      const newDate = new Date(date);
      const now = new Date();

      const dformat =
        [
          newDate.getHours(),
          newDate.getMinutes() < 10
            ? "0" + newDate.getMinutes()
            : newDate.getMinutes(),
        ].join(":") +
        " " +
        (now.getDate() === newDate.getDate() &&
        now.getMonth() === newDate.getMonth()
          ? ""
          : [
              newDate.getDate() < 10
                ? "0" + newDate.getDate()
                : newDate.getDate(),
              newDate.getMonth() + 1 < 10
                ? "0" + (newDate.getMonth() + 1)
                : newDate.getMonth() + 1,
              newDate.getFullYear(),
            ].join("/"));

      return dformat;
    },
  },
};
</script>

<style lang="scss" scoped>
.avatar {
  & img {
    border-radius: 50%;
    width: 48px;
    height: 48px;
    object-fit: cover;

    box-shadow: 1px 0px 0px 0px rgba(0, 0, 0, 0.05) inset,
      -1px 0px 0px 0px rgba(0, 0, 0, 0.05) inset,
      0px 1px 0px 0px rgba(0, 0, 0, 0.05) inset,
      0px -1px 0px 0px rgba(0, 0, 0, 0.15) inset,
      0px 4px 4px -1px rgba(0, 0, 0, 0.12);
  }

  &--small {
    & img {
      border-radius: 50%;
      width: 16px;
      height: 16px;
      object-fit: cover;

      box-shadow: 1px 0px 0px 0px rgba(0, 0, 0, 0.05) inset,
        -1px 0px 0px 0px rgba(0, 0, 0, 0.05) inset,
        0px 1px 0px 0px rgba(0, 0, 0, 0.05) inset,
        0px -1px 0px 0px rgba(0, 0, 0, 0.15) inset,
        0px 4px 4px -1px rgba(0, 0, 0, 0.12);
    }
  }
}

.conversation__container {
  height: 40vh;
  overflow: auto;
  padding-bottom: 3em;

  @media screen and (max-width: 768px) {
    height: 100%;
  }
}

.message__container {
  min-height: 400px;
  height: 100%;
  padding: 2px 2px 1em 2px;
  flex-direction: column-reverse;
  overflow: auto;
}
.chat {
  padding-top: 80px;
  padding-bottom: 80px;

  &__message {
    // max-width: 40vw;
  }

  &__header {
    position: absolute;
    top: 0.1em;
    right: 0.1em;
    left: 0.1em;
    border-radius: 0.5em;
    padding: 0.5em;
  }

  &__input {
    position: absolute;
    bottom: 0.1em;
    right: 0.1em;
    left: 0.1em;
    border-radius: 0.5em;
    padding: 0.5em;
  }
}

.message {
  padding: 0.5em;
  border-radius: 0.5em;
  max-width: 400px;
  word-wrap: break-word;

  @media screen and (max-width: 768px) {
    max-width: 70vw;
  }

  &--me {
    background: #6f382b;
    color: #fff;
  }

  &--partner {
    background: #fff;
    padding-top: 0.25em;
  }

  &--noti {
    background: none;
    color: #757575;
  }
}

.revert {
  flex-direction: row-reverse;
}

.border-brown {
  border: 1px solid #6f382b;
}

.chat-page {
  padding-bottom: 4em;
  height: 80vh;

  @media screen and (max-width: 768px) {
    height: calc(100vh - 80px);
    padding-bottom: 0;
  }
}

::-webkit-scrollbar {
  width: 2px; /* Đặt chiều rộng cho thanh cuộn dọc */
  height: 2px; /* Đặt chiều cao cho thanh cuộn ngang */
}
::-webkit-scrollbar-corner {
  background-color: transparent; /* Không hiển thị phần góc dưới cùng */
}
</style>